/* eslint-disable import/prefer-default-export */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  FC, useRef, useEffect, ReactNode, useState,
} from 'react';

interface ElementInViewProps {
  children: ReactNode | ((isInView: boolean) => ReactNode);
  onInView?: () => void;
  percentInView?: number;
}

export const ElementInView: FC<ElementInViewProps> = ({
  children,
  onInView,
  percentInView = 0,
}) => {
  const [isInView, setIsInView] = useState<boolean>(false);
  const triggerRef = useRef<HTMLDivElement>(null);
  const observerRef = useRef<IntersectionObserver | null>(null);

  useEffect(() => {
    if (triggerRef.current) {
      observerRef.current = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (
              entry.isIntersecting
              && entry.intersectionRatio >= percentInView / 100
            ) {
              setIsInView(true);
              if (onInView) onInView();
              if (observerRef.current && triggerRef.current) {
                observerRef.current.unobserve(triggerRef.current);
              }
            }
          });
        },
        {
          threshold: Array.from({ length: 101 }, (_, i) => i / 100),
        },
      );

      observerRef.current.observe(triggerRef.current);
    }

    return () => {
      if (observerRef.current && triggerRef.current) {
        observerRef.current.unobserve(triggerRef.current);
      }
    };
  }, [onInView, percentInView]);

  const isRenderProps = typeof children === 'function';

  return (
    <div ref={triggerRef}>{isRenderProps ? children(isInView) : children}</div>
  );
};
