import { logCustomAttributes, logError, logEvent } from '@red-digital/bricks';
import { MonarchEvaluationsError } from '@redventures/cohesion-utils-react';
import { MonarchEvaluationsValue } from 'pages';
import { SyntheticEvent } from 'react';

export const cohesionReady = () => {
    const {
        anonymousId = 'null',
        sessionId = 'null',
        instanceId = 'null',
    } = window?._Cohesion || {};
    const attributes = [
        { name: 'cohesion.anonymousId', value: anonymousId },
        { name: 'cohesion.sessionId', value: sessionId },
        { name: 'cohesion.instanceId', value: instanceId },
        { name: 'enduser.id', value: anonymousId },
    ];

    logCustomAttributes(attributes);
    logEvent('cohesion:ready');
};

export const cohesionCatch = (error: unknown) => {
    console.error('cohesion:catch:', error);
    logError(`cohesion:catch: ${error}`);
    logEvent('cohesion:catch');
};

export const monitorOnError = () => {
    console.error('Cohesion script - failed to load');
    logError(`cohesion:script: failed to load`);
};

// Monarch 'done' is fired after the Monarch native auto call is finished
// Future add of monitoring for manual monarch requests if applicable
export const monarchDone = (err: MonarchEvaluationsError, evaluation: MonarchEvaluationsValue) => {
    if (err) {
        const errString = JSON.stringify(err);
        logError(`autoMonarch:error ${errString}`);
        logEvent('autoMonarch:error', { error: errString });
    } else {
        const evaluationResult = evaluation?.source?.trafficFlow;
        const evaluationValue = evaluation?.source?.trafficFlow?.value;

        if (evaluationValue) {
            let audienceName = evaluationResult?.audienceName || '';
            let experienceName = evaluationValue?.experienceName || '';
            let experienceNumber = evaluationValue?.experienceNumber || '';
            let experimentName = evaluationResult?.experimentName || '';
            if (evaluationResult?.isPreview) {
                audienceName = '';
                experienceName = 'Preview';
                experienceNumber = -1
                experimentName = '';
            }

            // Send attributes to New Relic
            logCustomAttributes([
                { name: `autoMonarch.experienceName`, value: experienceName },
                { name: `autoMonarch.experienceNumber`, value: experienceNumber },
                { name: 'cohesion.audience', value: audienceName },
                { name: 'cohesion.experienceName', value: experienceName },
                { name: 'cohesion.experience', value: `${experienceNumber}` },
                { name: 'cohesion.experimentName', value: experimentName },
                { name: 'cohesion.renderedExperienceSource', value: 'autoMonarch' },
            ]);

            logEvent('autoMonarch:evaluationReceived', {
                experienceValues: evaluationValue?.experienceValues?.length,
            });
        }
        logEvent('autoMonarch:done');
    }
};
