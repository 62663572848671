import { createRef } from 'react';
import useIntersectionObserver from '../hooks/useIntersectionObserver';
import useGCLID from './gclid';

/**
 * useElementViewed
 * This hook allows us to easily track when any given element is viewed by a user, using the [Intersection Observer API](https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API).
 * It returns a {@link React.RefObject} that can be passed to any given element.
 *
 * @func
 * @category Segment
 * @template {HTMLElement} T
 * @param observerThreshold, optional and defaults to .5, sets what percentage of the element needs to be visible for the event to fire
 * @return {RefObject<T>}
 */
export default function useElementViewed<T extends HTMLElement>(
  observerThreshold = 0.5,
): React.RefObject<T> {
  const ref = createRef<T>();
  const gclid = useGCLID();

  // optional threshold prop for taller sections

  useIntersectionObserver(
    { refs: [ref], options: { threshold: observerThreshold }, once: true },
    (entries) => {
      entries.forEach((entry) => {
        analytics.track('Element Viewed', {
          gclid,
          elementPosition: entry.target.getAttribute('data-element-position'),
          elementName: entry.target.getAttribute('data-element-name'),
          elementType: entry.target.getAttribute('data-element-type') ?? entry.target.tagName.toUpperCase(),
          url: window.location.href,
          path: window.location.pathname,
        });
      });
    },
  );

  return ref;
}
