import { RefObject, useEffect } from 'react';

/**
 * useIntersectionObserver
 * This hook leverages the [Intersection Observer API](https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API), which allows us to fire a callback of our choosing when a given element is visible in the DOM.
 *
 * @func
 * @category Hooks
 * @param {Object} args
 * @param {HTMLElement[]} args.refs - An array of HTML elements to observe
 * @param {IntersectionObserverInit.root} [args.root] - The root element to compare against
 * @param {Omit<IntersectionObserverInit, 'root'>} [args.options] - Additional options
 * @param {boolean} [args.once] - Whether to fire callback once, or every time the element intersects with the root
 * @param {IntersectionObserverCallback} callback - The callback to fire when the element intersects wth the root
 */
export default function useIntersectionObserver(
  {
    refs,
    root,
    options,
    once,
  }: {
    refs: RefObject<HTMLElement>[];
    root?: IntersectionObserverInit['root'];
    options?: Omit<IntersectionObserverInit, 'root'>;
    once?: boolean;
  },
  callback: IntersectionObserverCallback
): void {
  useEffect(() => {
    const observer = new IntersectionObserver(
      (elements, ob) => {
        for (const el of elements) {
          if (el.isIntersecting) {
            callback([el], ob);

            if (once) {
              ob.unobserve(el.target);
            }
          }
        }
      },
      { root, ...options }
    );

    refs.forEach((ref) => ref && ref.current && observer.observe(ref.current));

    return () => observer.disconnect();
  }, [root, refs, callback, once, options]);
}
