import { MonarchEvaluationsError } from '@redventures/cohesion-utils-react';
import { cohesionReady, monarchDone, cohesionCatch, monitorOnError } from './monarchEvents'
import { MonarchEvaluationsValue } from 'pages';

export const setMonarchMonitoring = () => {

    try {
        // Send a page action to new relic when cohesion is ready.
        window?.cohesion('ready', cohesionReady);

        // Event listener that will listen for Monarch to be done loading
        window?.cohesion('monarch:done', (err: MonarchEvaluationsError, evaluation: MonarchEvaluationsValue) => { monarchDone(err, evaluation) });

        // Event listener that will listen for any errors that occur in while loading the Cohesion SCript
        window?.cohesion('error', monitorOnError);
    } catch (error: unknown) {
        cohesionCatch(error);
    }
}