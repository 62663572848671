import { FunctionComponent } from 'react';
import Image from 'next/image';
import styles from './LoadingSkeleton.module.scss';

/**
 * LoadingSkeletonProps
 */
interface LoadingSkeletonProps {
  /**
   * loading state for gif, defaults to lazy
   * @inner
   */
  loading?: 'eager' | 'lazy'
}

const LoadingSkeleton: FunctionComponent<LoadingSkeletonProps> = ({
  loading = 'lazy',
}) => {
  const baseUrl = process.env.baseUrl;

  return (
    <div className={styles.root}>
      <div className={styles.logoWrapper}>
        <Image
          src={`${baseUrl}/images/animated_logo.gif`}
          alt='Walmart Plus Logo'
          width={239}
          height={100}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default LoadingSkeleton;
