import React, { HTMLAttributes, useEffect, useState } from "react";
import useElementViewed from "~/lib/segment/useElementViewed";
import { useMonarchSlot } from "@redventures/cohesion-utils-react";
import { convertCamelCaseToSnakeCase, toCamelCase } from "~/helper/editableHelpers";

/**
 * MonarchSection
 * This component wraps a Monarch section and auto-handles the Element Viewed event via the {@link useElementViewed} hook
 *
 * @func
 * @param {Object} props
 * @param {string} props.elementPosition - The section on the page, this value should contain the same characters as the Monarch slotId
 * @return {JSX.element}
 */
function MonarchSection({
  children,
  elementPosition,
}: {
  children: React.ReactElement<{ placement?: string }>;
  elementPosition: string;
} & HTMLAttributes<HTMLElement>): JSX.Element {

  const sectionRef = useElementViewed();
  const [hasChildren, setHasChildren] = useState(false);
  const slotId = toCamelCase(elementPosition);
  const result = useMonarchSlot(slotId);
  const elementName = convertCamelCaseToSnakeCase(result?.slot?.variantCustomId) ?? 'BASE_COMPONENT';

  useEffect(() => {
    if (React.isValidElement(children)) {
      if (result.data) {
        setHasChildren(true);
      } else {
        setHasChildren(children.props.children !== null && children.props.children !== undefined);
      }
    }
  }, [children, hasChildren, result]);

  return (
    <section
      ref={hasChildren ? sectionRef : null}
      data-element-position={elementPosition}
      data-element-name={elementName}
      data-element-type='SLOT'
    >
      {children}
    </section>
  );
}

export default MonarchSection;
