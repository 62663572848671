/* eslint-disable react/no-unused-prop-types */
import type { AnchorHTMLAttributes, FunctionComponent } from 'react';
import CTAButton, { CTAColorVariant } from '~/components/shared/CTAButton';
import Tooltip from '~/components/shared/Tooltip';
import { handleLinks, handleSuperscripts } from '~/helper/editableHelpers';
import { ResponsiveImage } from '~/helper/ResponsiveImage';
import styles from './Hero.module.scss';
import { useMonarchSlotContext } from '@redventures/cohesion-utils-react';

/**
 * @category Hero
 * @extends AnchorHTMLAttributes<HTMLAnchorElement>
 * */

interface HeroProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  /**
   * Eyebrow text
   *
   * @inner
   * @optional
   */
  eyebrow?: string;

  /**
   * Headline text
   *
   * @inner
   * @optional
   */
  headline?: string;

  /**
   * Body text, any superscripts are denoted using `_[number]_`
   *
   * @inner
   * @optional
   */
  body?: string;

  /**
   * Text for the CTA Button
   *
   * @inner
   * @optional
   */
  CTAButtonText?: string;

  /**
   * Text for the disclaimer underneath the CTA Button, any superscripts are denoted using `_[number]_`
   *
   * @inner
   * @optional
   */
  CTAButtonDisclaimer?: string;

  /**
   * Link for the disclaimer underneath the CTA Button
   *
   * @inner
   * @optional
   */
  CTAButtonDisclaimerLink?: string;

  /**
   * CTA button link
   *
   * @inner
   * @optional
   */
  CTAButtonLink?: string;

  /**
   * True or false value that determines whether to display the secondary CTA
   *
   * @inner
   * @optional
   */
  hasSecondaryCTAButton?: boolean;

  /**
   * Variant styling of CTAButton
   *
   * @inner
   * @optional
   */
  CTAButtonVariant?: CTAColorVariant;

  /**
   * Text for the optional secondary CTA Button
   *
   * @inner
   * @optional
   */
  secondaryCTAButtonText?: string;

  /**
   * Variant styling of optional secondary CTAButton
   *
   * @inner
   * @optional
   */
  secondaryCTAButtonVariant?: CTAColorVariant;

  /**
   * Link for the optional secondary CTA
   *
   * @inner
   * @optional
   */
  secondaryCTAButtonLink?: string;

  image?: {
    mobile: string,
    tablet: string,
    desktop: string,
    alt: string
  };
}

/**
 *
 * @category Hero
 * @subcategory Components
 *
 * @param {HeroProps} props
 *
 * @example
 * // Renders the Hero given the metadata sent to it
 * <Hero
          eyebrow: 'Try Walmart+ free for 30 days',
          headline: 'Milk, eggs, diapers, savings. It’s all on the way.',
          body: 'Get the best of Walmart with the membership that can save you $1,300+ a year._1_ Enjoy member fuel prices, free same-day grocery delivery_2_ & much more.',
          CTAButtonText: 'Claim your free trial',
          CTAButtonDisclaimer: '*Excludes most Marketplace items, location & freight surcharges.',
          CTAButtonVariant: 'primary-blue',
          CTAButtonLink: 'https://www.walmart.com/plus/plans',
          hasSecondaryCTAButton: false,
          secondaryCTAButtonText: 'Claim your free trial',
          secondaryCTAButtonLink: 'https://www.walmart.com/plus/plans',
          secondaryCTAButtonVariant: 'text-link',
          image: {
            mobile: 'WALM_22_0157_132-low-m.jpeg',
            tablet: 'WALM_22_0157_132-low-d.jpeg',
            desktop: 'WALM_22_0157_132-low-d.jpeg',
            alt: 'Box of shipped Walmart goodies and a dog playing with toys out of it.',
          }
    />
 *
 */

const DEFAULT_PROPS = {
  eyebrow: 'Get free shipping with no order minimum*',
  headline: 'Order as often as you want with free shipping',
  body: 'Save on shipping fees as a Walmart+ member. Whether there’s one item or twenty in your cart, get free shipping with no order minimum—as early as the next day.',
  CTAButtonText: 'Claim your free trial',
  CTAButtonDisclaimer:
    '*Excludes most Marketplace items, location & freight surcharges.',
  CTAButtonDisclaimerLink: '',
  CTAButtonVariant: 'primary-blue' as CTAColorVariant,
  CTAButtonLink: 'https://www.walmart.com/plus/plans',
  hasSecondaryCTAButton: false,
  secondaryCTAButtonText: 'Or try free for 30 days',
  secondaryCTAButtonLink: 'https://www.walmart.com/plus/plans',
  secondaryCTAButtonVariant: 'text-link' as CTAColorVariant,
  image: {
    mobile: 'WALM_22_0157_132-low-m.jpeg',
    tablet: 'WALM_22_0157_132-low-d.jpeg',
    desktop: 'WALM_22_0157_132-low-d.jpeg',
    alt: 'Box of shipped Walmart goodies and a dog playing with toys out of it.',
  }
};

const Hero: FunctionComponent<HeroProps> = () => {
  const { data } = useMonarchSlotContext<typeof DEFAULT_PROPS>();
  const {
    eyebrow,
    headline,
    body,
    CTAButtonText,
    CTAButtonDisclaimer,
    CTAButtonDisclaimerLink,
    CTAButtonVariant,
    CTAButtonLink,
    hasSecondaryCTAButton,
    secondaryCTAButtonLink,
    secondaryCTAButtonText,
    secondaryCTAButtonVariant,
    image
  } = data ?? DEFAULT_PROPS;

  const bodyWithSuperscript = handleSuperscripts(body, styles.superscript);
  const CTAButtonDisclaimerWithSuperscript = handleLinks(
    CTAButtonDisclaimer,
    /(_LINK_[^*]*_LINK_)/,
    CTAButtonDisclaimerLink,
    styles.CTAButtonDisclaimerLink,
    0,
    styles.superscript
  );

  const eyebrowWithSuperscript = handleSuperscripts(
    eyebrow,
    styles.superscript
  );
  const headlineWithSuperscript = handleSuperscripts(
    headline,
    styles.superscript
  );
  const CTAButtonTextWithSuperscript = handleSuperscripts(
    CTAButtonText,
    styles.superscript
  );
  const baseUrl = process.env.baseUrl;
  const imgPath = `${baseUrl}/images/sections/hero/`;

  return (
    <div className={styles.heroWrapper}>
      <div className={styles.contentWrapper}>
        <p className={styles.eyebrow}>{eyebrowWithSuperscript}</p>
        <h1 className={styles.headline}>{headlineWithSuperscript}</h1>
        <div className={`body-1 ${styles.body}`}>
          {bodyWithSuperscript}
          <Tooltip
            disclaimer={{
              id: 'tooltip-hero',
              text: CTAButtonDisclaimerWithSuperscript,
            }}
            elementPosition='Hero'
          />
        </div>
        <div className={styles.CTAButtonWrapper}>
          <CTAButton
            variant={CTAButtonVariant}
            elementPosition='HERO'
            elementType='SIGN_UP_CTA_PRIMARY'
            href={CTAButtonLink}
          >
            {CTAButtonTextWithSuperscript}
          </CTAButton>
          {hasSecondaryCTAButton && (
            <CTAButton
              elementPosition='HERO'
              variant={secondaryCTAButtonVariant}
              elementType='SIGN_UP_CTA_SECONDARY'
              className={styles.ctaButton}
              href={secondaryCTAButtonLink}
            >
              {secondaryCTAButtonText}
            </CTAButton>
          )}
        </div>
      </div>
      <div className={styles.imgWrapper}>
        <div className={styles.heroImgWrapper}>
          <ResponsiveImage
            imgPath={imgPath}
            img={{
              mobile: image.mobile,
              tablet: image.tablet,
              desktop: image.desktop,
            }}
            className={styles.heroImg}
            alt={image.alt}
            width={720}
            height={565}
            loading='eager'
          />
        </div>
      </div>
    </div>
  );
};

export default Hero;
