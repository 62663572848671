import { FunctionComponent } from 'react';
import LoadingSkeleton from '~/components/Layout/LoadingSkeleton';
import styles from './Skeleton.module.scss';

const joinClassNames = (className: string) => `${styles.root} ${className}`;

export const HeaderSkeleton: FunctionComponent<{}> = () => (
  <div className={joinClassNames(styles.header)} />
);

export const HeroSkeleton: FunctionComponent<{}> = () => (
  <div className={joinClassNames(styles.hero)}>
    <LoadingSkeleton />
  </div>
);

export const Section01Skeleton: FunctionComponent<{}> = () => (
  <div className={joinClassNames(styles.section01)} />
);

export const Section02Skeleton: FunctionComponent<{}> = () => (
  <div className={joinClassNames(styles.section02)} />
);

export const Section04Skeleton: FunctionComponent<{}> = () => (
  <div className={joinClassNames(styles.section04)} />
);

export const HyperlinkBannerSkeleton: FunctionComponent<{}> = () => (
  <div className={joinClassNames(styles.hyperlinkBanner)} />
);

export const FeaturesSkeleton: FunctionComponent<{}> = () => (
  <div className={joinClassNames(styles.features)} />
);

export const MemberRewardsSkeleton: FunctionComponent<{}> = () => (
  <div className={joinClassNames(styles.memberRewards)} />
);

export const CompareCardsSkeleton: FunctionComponent<{}> = () => (
  <div className={joinClassNames(styles.compareCards)} />
);

export const BannerCTASkeleton: FunctionComponent<{}> = () => (
  <div className={joinClassNames(styles.bannerCTA)} />
);

export const TestimonialsSkeleton: FunctionComponent<{}> = () => (
  <div className={joinClassNames(styles.testimonials)} />
);
export const SunriseBannerSkeleton: FunctionComponent<{}> = () => (
  <div className={joinClassNames(styles.sunriseBanner)} />
);

export const FAQSSkeleton: FunctionComponent<{}> = () => (
  <div className={joinClassNames(styles.faq)} />
);

export const FooterSkeleton: FunctionComponent<{}> = () => (
  <div className={joinClassNames(styles.footer)} />
);

export const AAStickyMobileCTASkeleton: FunctionComponent<{}> = () => (
  <div className={joinClassNames(styles.aaStickyMobileCTA)} />
);

export const StickyMobileCTASkeleton: FunctionComponent<{}> = () => (
  <div className={joinClassNames(styles.stickyMobileCTA)} />
);
