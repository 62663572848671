import { FAQProps } from '.';

/*
Link formatting: links are parsed using regex and must follow same structure and be filled out entirely to be parsed correctly
_LINK_[link-text]_[href]_

* _LINK_ denotes the start of a link and whole section must end with _

*/

const baseSiteFAQs: FAQProps['questions'] = [
  {
    question: 'What types of Walmart+ plans are available?',
    answers: ['Monthly: $12.95/month*.', 'Annual: $98/year*.'],
    disclaimer: '* Plus applicable taxes.',
  },
  {
    question: 'How do I cancel my Walmart+ plan or trial?',
    answers: [
      'You can cancel from your _LINK_Walmart+ account page_https://www.walmart.com/grocery/account/plus/overview_ or _LINK_contact customer care_https://walmart.com/help_.',
    ],
  },
  {
    question: 'Where can I manage my Walmart+ plan?',
    answers: [
      'You can manage your plan in the _LINK_Walmart+ account page_https://www.walmart.com/grocery/account/plus/overview_.',
    ],
  },
  {
    question: 'Who do I contact for help with my Walmart+ plan?',
    answers: [
      'If you need assistance, please visit our _LINK_help center_https://www.walmart.com/help_.',
    ],
  },
];

export default baseSiteFAQs;
