/* eslint-disable react/no-unused-prop-types */
import { FunctionComponent } from 'react';
import { useMonarchRule } from '@redventures/cohesion-utils-react';
import { MonarchEvaluationsValue } from 'pages/index';
import BoxedPromotionBanner from '~/components/editable/BoxedPromotionBanner';
import { DEFAULT_PROPS } from 'components/editable/BoxedPromotionBanner/data';

const FuelPromotion: FunctionComponent = () => {
  const doublefuel = useMonarchRule<MonarchEvaluationsValue>('doublefuel');
  const value = doublefuel?.value?.data;
  const {
    variant,
    bannerColor,
    blueBlobSubheadline,
    blueBlobBody,
    month,
    date,
    eyebrow,
    headline,
    subheadline,
    disclaimer,
    disclaimerLink,
    CTAButtonText,
    CTAButtonVariant,
    CTAButtonLink,
    hasSecondaryCTAButton,
    secondaryCTAButtonText,
    secondaryCTAButtonVariant,
    secondaryCTAButtonLink,
    images,
  } = value ?? DEFAULT_PROPS;
  return (
    <BoxedPromotionBanner
      variant={variant}
      bannerColor={bannerColor}
      blueBlobSubheadline={blueBlobSubheadline}
      blueBlobBody={blueBlobBody}
      month={month}
      date={date}
      eyebrow={eyebrow}
      headline={headline}
      subheadline={subheadline}
      disclaimer={disclaimer}
      disclaimerLink={disclaimerLink}
      CTAButtonText={CTAButtonText}
      CTAButtonVariant={CTAButtonVariant}
      CTAButtonLink={CTAButtonLink}
      hasSecondaryCTAButton={hasSecondaryCTAButton}
      secondaryCTAButtonText={secondaryCTAButtonText}
      secondaryCTAButtonVariant={secondaryCTAButtonVariant}
      secondaryCTAButtonLink={secondaryCTAButtonLink}
      images={images}
    />
  );
};

export default FuelPromotion;
